<template>
	<div>
		<vx-card>
			<div class="vx-row">
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Registrar*</label>
					<v-select class="w-full" v-model="formData['registrar']" multiple :options="formRegistrars" :clearable="false"></v-select>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">TLD*</label>
					<v-select class="w-full" v-model="formData['tld']" multiple :options="formTLDs" :clearable="false"></v-select>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Keywords*</label>
					<v-select class="w-full" v-model="formData['keyword']['existing']" :options="formKeywords"></v-select>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Custom Keyword</label>
					<vs-input class="w-full" v-model="formData['keyword']['custom']" :disabled="formData['keyword']['existing']"/>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Placement*</label>
					<v-select class="w-full" v-model="formData['placement']" :options="formInit['placement']" :clearable="false"></v-select>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Reason*</label>
					<v-select class="w-full" v-model="formData['reason']" :options="formReasons" :clearable="false"></v-select>
				</div>
				<div class="vx-col w-full mb-2">
					<div class="pt-4">
						<vs-switch color="success" v-model="formData['active']">
							<span slot="on">Activate</span>
							<span slot="off">Deactivate</span>
						</vs-switch>
					</div>
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButton" id="button-with-loading" class="mb-2" @click="openConfirm()" :disabled="user_role != 'admin' || (!formInit['done'] || (formData['registrar'] == '' || formData['tld'] == '' || formData['source'] == '' || (formData['keyword']['existing'] == null && formData['keyword']['custom'] == '')))">Submit</vs-button>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import vSelect from 'vue-select'

	export default{
		components: {
			'v-select': vSelect,
		},
		data() {
			return {
				formData: {
					registrar: '',
					tld: [],
					keyword: {
						existing: null,
						custom: '',
					},
					placement: 'Contains',
					reason: {label:'Malware/Phishing', value:176},
					active: false,
				},
				formInit: {
					done: false,
					registrars: [],
					tlds: [],
					placement: [
						'Starts with',
						'Contains',
						'End with'
					],
					reasons: [],
				},
			}
		},
		watch: {
			formTLDs(val) {
				this.formData['tld'] = val;
			},
			'formData.keyword.existing'(val) {
				if(val) {
					this.formData['keyword']['custom'] = '';
				}
			},
		},
		computed: {
			blacklistedKeywords: function(){
				return this.$store.getters.getBlacklistedKeywords;
			},
			formRegistrars: function(){
				let registrarArray = this.formInit['registrars'].map(x => {
					return {
						label: x['organization'],
						value: x['registrar_id']
					}
				});
				
				return registrarArray;
			},
			formTLDs: function(){
				let tldArray = this.formInit['tlds'].map(x => {
					return {
						label: x['tld'],
						value: x['id']
					}
				});
				
				return tldArray;
			},
			formKeywords: function(){
				let keywordsArray = this.blacklistedKeywords.map(x => {
					return {
						label: x['keyword'],
						value: x['id']
					}
				});
				
				return keywordsArray;
			},
			formReasons: function(){
				let reasonsArray;

				reasonsArray = this.formInit['reasons'].filter(x => x.source_id == 17).map(x => {
					return {
						label: x['name'],
						value: x['id']
					}
				});

				return reasonsArray;
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading > .con-vs-loading");
			},
			openConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Create this RTK Rule?',
					accept: this.createRTKRule
				});
			},
			createRTKRule() {
				let self = this;
				// Show loader
				self.showButtonLoading();
				// Post Axios Call
				this.$axiosSecure.post('/rtk-rules-create', {
					...self.formData,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'RTK rule creation success',
						text: 'The RTK rule has been created'
					});
					
					// Hide loader
					self.hideButtonLoading();
					// self.$router.push({ path: '/rtk-rule-list' });
				})
				.catch((error) => {
					// Hide loader
					self.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
		},
		mounted() {
			let self = this;
			// Get Registrars JSON
			this.$axiosSecure.get('/get-registrars')
			.then((response) => {
				self.formInit['registrars'] = response.data;
				
				// Get TLD JSON
				self.$axiosSecure.get('/get-tlds')
				.then((response) => {
					self.formInit['tlds'] = response.data;

					// Get Keywords JSON
					self.$axiosSecure.get('/get-trademarked-keywords')
					.then((response) => {
						this.$store.dispatch('setBlacklistedKeywords', response.data);

						// Get Categories JSON
						self.$axiosSecure.get('/get-categories')
						.then((response) => {
							self.formInit['reasons'] = response.data;
							// Enable Submit
							self.formInit['done'] = true;
						})
						.catch((error) => {
							// Error notification
							self.$vs.notify({
								color: 'danger',
								title: 'Something went wrong',
								text: 'Please contact the server admin'
							});

							console.log(error);
						});
					})
					.catch((error) => {
						// Error notification
						self.$vs.notify({
							color: 'danger',
							title: 'Something went wrong',
							text: 'Please contact the server admin'
						});

						console.log(error);
					});
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
			});
		}
	}
</script>
